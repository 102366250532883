//inteface
import {
  User,
  CreateUserRequest,
  UserResponse,
  CreateUserErrorResponse,
  CreateUserError,
  UserErrorResponse,
  BusinessUserTypeResponse,
  BusinessUserTypeRequest,
  BussinesUserType,
  UserErrorFullResponse,
  AdminUserResponseType,
  AdminUserErrorResponseType,
  CreateAdminUserRequestType
} from '@interfaces/user/user';
//error
import { ErrorRes, ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
//service
import {
  authUser,
  createBusinessUser,
  createSportCenterUser,
  createUser,
  deleteUser,
  deleteUsers,
  updateUser
} from '@services/user/user';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useUser = (
  onUserSuccess: (data: User) => void,
  onError: (err: UserErrorResponse) => void
): UseMutationResult<{ data: UserResponse }, UserErrorFullResponse> =>
  useMutation({
    mutationFn: authUser,
    onSuccess: data => onUserSuccess(data.data.data),
    onError: err => onError(err.response)
  });

export const useUpdateUser = (
  onSuccess: (data: User) => void,
  onError: (err: CreateUserError) => void
): UseMutationResult<
  { data: UserResponse }, // response
  CreateUserErrorResponse,
  User, // request
  (data: User) => void // on success
> =>
  useMutation({
    mutationFn: updateUser,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useCreateUser = (
  onSuccess: (data: User) => void,
  onError: (err: CreateUserError) => void
): UseMutationResult<
  { data: UserResponse }, // response
  CreateUserErrorResponse,
  CreateUserRequest, //request
  (data: User) => void // on success
> =>
  useMutation({
    mutationFn: createUser,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteUser = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  Number, //request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteUser,
    onSuccess: data => onSuccess({ message: 'Sucessfully deleted user' }),
    onError: err => onError(err.response.data)
  });

export const useDeleteUsers = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  number[], // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteUsers,
    onSuccess: data => onSuccess({ message: 'Successfully deleted users' }),
    onError: err => onError(err.response.data)
  });

export const useCreateBusinessUser = (
  onSuccess: (data: BussinesUserType) => void,
  onError: (err: Error) => void
): UseMutationResult<
  { data: BusinessUserTypeResponse }, // response
  Error,
  BusinessUserTypeRequest, //request
  (data: BussinesUserType) => void // on success
> =>
  useMutation({
    mutationFn: createBusinessUser,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err)
  });

export const useCreateAdminUser = (
  onSuccess: (data: AdminUserResponseType) => void,
  onError: (err: AdminUserErrorResponseType) => void
): UseMutationResult<
  { data: AdminUserResponseType }, // response
  AdminUserErrorResponseType,
  CreateAdminUserRequestType, //request
  (data: AdminUserResponseType) => void // on success
> =>
  useMutation({
    mutationFn: createSportCenterUser,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });
