//react
import { useMemo, FC, useEffect } from 'react';
//react-router
import { useNavigate, useParams, NavLink } from 'react-router-dom';
//hooks
import useTablesLogic from '@hooks/tables/useTablesLogic';
import { useSportCenterUsers } from '@api/queries/user/user';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
//containers
import TableHeaderActions from '@containers/TableHeaderAction/TableHeaderActions';
//components
import Loading from '@components/Loading/Loading';
//interfaces
import {
  AdminUserInfoType,
  AdminUserResponseType,
  AdminUserType,
  User
} from '@interfaces/user/user';
//layout
import { TableLayout } from '@layout/TableLayout/TableLayout';
//material-react-table
import { type MRT_ColumnDef } from 'material-react-table';
//mui
import { Button, Grid, Card } from '@mui/material';
// icons
import AddIcon from '@mui/icons-material/Add';
//atoms
import { currentCMSAtom } from '@atoms/currentCMS';
import { popupAtom } from '@atoms/popupAtom';
//helpers
import { checkPermission } from '@helpers/utility';
//enums
import { PermissionsEnum } from '@enum/permissionsEnum';

const AdminUsersPage: FC = (): JSX.Element | null => {
  const navigate = useNavigate();
  const params = useParams();
  const sportCenterId = params.sportCenterId ? +params.sportCenterId : 0;
  const { t } = useTranslation();
  const currentCMS = useRecoilValue(currentCMSAtom);
  const setPopup = useSetRecoilState(popupAtom);

  const {
    pagination,
    setPagination,
    tableInstanceRef,
    handleDeleteRow,
    handleViewMoreRow,
    handleSearch,
    sortBy,
    sortDirection,
    handleSortTables,
    checkSort,
    handleClearSort,
    handleSubmitFilter,
    filterValues,
    controlFilter,
    filterValue,
    handleClearFilter,
    anchorSortEl,
    anchorEl,
    anchorMenuItem,
    handleClick,
    handleClose
  } = useTablesLogic();

  const { data, isLoading: isLoadingSportCenterUsers } = useSportCenterUsers(
    sportCenterId,
    pagination.pageIndex + 1,
    pagination.pageSize,
    filterValues.search,
    filterValues.gender.toString(),
    sortBy,
    sortDirection,
    onUsersErrorError
  );

  function onUsersErrorError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const columnsAllUsers = useMemo<MRT_ColumnDef<AdminUserType | {}>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name_and_surname'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          const rowType = row.original as AdminUserType;
          return (
            <NavLink to={`edit-user/${rowType.id}`} end style={{ color: 'black' }}>
              {rowType.first_name + ' ' + rowType.last_name}
            </NavLink>
          );
        }
      },
      {
        accessorKey: 'phone',
        header: t('phone'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'email',
        header: t('email'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'category',
        header: t('category'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          return <>{t('soon')}</>;
        }
      },
      {
        accessorKey: 'appointments_count',
        header: t('appointments_count'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      }
    ],
    [t]
  );

  return (
    <Grid container className="fullWidth">
      {checkPermission(currentCMS, PermissionsEnum.create_users) && (
        <Grid container justifyContent={'flex-end'} alignItems="end" p={3} pt={0}>
          <Grid item sm={5} className="row justify-end">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('create-user')}
            >
              {t('newUser')}
            </Button>
          </Grid>
        </Grid>
      )}
      <Card sx={{ mx: 3 }} className="fullWidth">
        <TableHeaderActions
          tableVariant="adminUsers"
          handleSearch={handleSearch}
          hideActionBtn
          //sort
          handleSortTables={handleSortTables}
          checkSort={checkSort}
          handleClearSort={handleClearSort}
          //filter
          handleClearFilter={handleClearFilter}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorFilterEl={anchorEl}
          anchorMenuItem={anchorMenuItem}
          anchorSortEl={anchorSortEl}
          controlFilter={controlFilter}
          handleSubmitFilter={handleSubmitFilter}
          filterValue={filterValue}
        />
        <Grid item lg={12} ml={'20px'} mr={'20px'}>
          {data && !isLoadingSportCenterUsers ? (
            <TableLayout
              tableVariant="adminUsers"
              rows={data?.data?.data}
              columns={columnsAllUsers}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={data?.data?.meta?.total}
              tableInstanceRef={tableInstanceRef}
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default AdminUsersPage;
