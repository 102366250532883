//react
import { useMemo, FC } from 'react';
//recoil
import { useSetRecoilState } from 'recoil';
//atoms
import { popupAtom } from '@atoms/popupAtom';
//hooks
import useTablesLogic from '@hooks/tables/useTablesLogic';
import { useDeleteUser, useDeleteUsers } from '@api/mutations/user/user';
import { useUsers } from '@api/queries/user/user';
import { useTranslation } from 'react-i18next';
//containers
import TableHeaderActions from '@containers/TableHeaderAction/TableHeaderActions';
//components
import Loading from '@components/Loading/Loading';
//interfaces
import { User } from '@interfaces/user/user';
import { ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
//layout
import { TableLayout } from '@layout/TableLayout/TableLayout';
//material-react-table
import { type MRT_ColumnDef } from 'material-react-table';
//mui
import { Grid, Card } from '@mui/material';

const UsersPage: FC = (): JSX.Element | null => {
  const setPopup = useSetRecoilState(popupAtom);

  const { t } = useTranslation();

  const onDeleteSuccesss = (data: SuccessResponse) => {
    setSuccessfullyDeletedPopup(true);
    refetch();
  };

  const onDeleteError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: deleteUser } = useDeleteUser(onDeleteSuccesss, onDeleteError);
  const { mutate: deleteUsers } = useDeleteUsers(onDeleteSuccesss, onDeleteError);

  const {
    pagination,
    setPagination,
    tableInstanceRef,
    handleSearch,
    sortBy,
    sortDirection,
    handleSortTables,
    checkSort,
    handleClearSort,
    setSuccessfullyDeletedPopup,
    handleSubmitFilter,
    filterValues,
    controlFilter,
    filterValue,
    handleClearFilter,
    anchorSortEl,
    anchorEl,
    anchorMenuItem,
    handleClick,
    handleClose
  } = useTablesLogic(deleteUser, deleteUsers, 'superAdminUsers');

  const {
    data,
    refetch,
    isLoading: isLoadingUsers
  } = useUsers(
    pagination.pageIndex + 1,
    pagination.pageSize,
    filterValues.search,
    filterValues.gender.toString(),
    sortBy,
    sortDirection,
    onUsersError
  );

  function onUsersError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const columnsAllUsers = useMemo<MRT_ColumnDef<User | {}>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name_and_surname'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'phone',
        header: t('phone'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'email',
        header: t('email'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'date_of_birth',
        header: t('date_of_birth'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      }
    ],
    [t]
  );

  return (
    <Grid container>
      <Card sx={{ mx: 3 }} className="fullWidth">
        <TableHeaderActions
          tableVariant="users"
          handleSearch={handleSearch}
          hideActionBtn
          //sort
          handleSortTables={handleSortTables}
          checkSort={checkSort}
          handleClearSort={handleClearSort}
          //filter
          handleClearFilter={handleClearFilter}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorFilterEl={anchorEl}
          anchorMenuItem={anchorMenuItem}
          anchorSortEl={anchorSortEl}
          controlFilter={controlFilter}
          handleSubmitFilter={handleSubmitFilter}
          filterValue={filterValue}
        />
        <Grid item lg={12} ml={'20px'} mr={'20px'}>
          {data && !isLoadingUsers ? (
            <TableLayout
              tableVariant="users"
              rows={data?.data?.data}
              columns={columnsAllUsers}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={data?.data?.meta?.total}
              tableInstanceRef={tableInstanceRef}
              withActions
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default UsersPage;
