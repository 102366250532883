//interfaces
import {
  ChangePasswordRequestType,
  LoginRequest,
  LoginResponse,
  LogoutResponse,
  RecoverPasswordRequest,
  RecoverPasswordResponse,
  ResetPasswordFormRequestType
} from '@interfaces/user/login';
import {
  AdminUserResponseType,
  AllUsersResponse,
  BusinessUserTypeRequest,
  BusinessUserTypeResponse,
  CreateAdminUserRequestType,
  UserResponse
} from '@interfaces/user/user';
import { SuccessResponse } from '@interfaces/apiResponse';
import { CreateUserRequest, User } from '@interfaces/user/user';
//axios instance
import axiosInstance from '@services/config';

export const authUser = (): Promise<{ data: UserResponse }> => axiosInstance.get('/me');

export const createUser = (data: CreateUserRequest): Promise<{ data: UserResponse }> =>
  axiosInstance.post('/users', data);

export const updateUser = (user: User): Promise<{ data: UserResponse }> =>
  axiosInstance.put(`/users/${user.id}`, user);

export const singleUser = (id: Number): Promise<{ data: UserResponse }> =>
  axiosInstance.get(`/users/${id}`);

export const deleteUser = (id: Number): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/users/${id}`);

export const deleteUsers = (data: number[]): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/users/bulk/delete`, {
    params: {
      users: data
    }
  });

export const login = (data: LoginRequest): Promise<{ data: LoginResponse }> => {
  return axiosInstance.post('stateful/login', data);
};

export const logout = (): Promise<{ data: LogoutResponse }> => {
  return axiosInstance.post('stateful/logout');
};

export const recoverPassword = (
  data: RecoverPasswordRequest
): Promise<{ data: RecoverPasswordResponse }> => axiosInstance.post('/forgot-password', data);

export const users = (
  page: number,
  per_page: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string
): Promise<{ data: UserResponse }> => {
  return axiosInstance.get('/users', {
    params: {
      page: page,
      per_page: per_page,
      search: search,
      gender: gender,
      order_by: order_by,
      order_direction: order_direction
    }
  });
};

export const usersInfinity = (
  page: number,
  per_page: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string
): Promise<{ data: AllUsersResponse }> => {
  return axiosInstance.get('users/', {
    params: {
      page: !page ? 1 : page,
      per_page: per_page,
      search: search,
      gender: gender,
      order_by: order_by,
      order_direction: order_direction
    }
  });
};

export const sportCenterUsersInfinity = (
  sportCenter: number | undefined,
  page: number,
  per_page: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string
): Promise<{ data: AllUsersResponse }> => {
  return axiosInstance.get(`/users/by-sport-center/${sportCenter}`, {
    params: {
      sportCenter: sportCenter,
      page: !page ? 1 : page,
      per_page: per_page,
      search: search,
      gender: gender,
      order_by: order_by,
      order_direction: order_direction
    }
  });
};

export const createBusinessUser = (
  data: BusinessUserTypeRequest
): Promise<{ data: BusinessUserTypeResponse }> => axiosInstance.post('/users', data);

export const resetPassword = (
  data: ResetPasswordFormRequestType
): Promise<{ data: RecoverPasswordResponse }> => axiosInstance.post('/reset-password', data);

export const changePassword = (data: ChangePasswordRequestType): Promise<{ data: UserResponse }> =>
  axiosInstance.put('/users/update-password', data);

// sport center users

export const getSportCenterUsers = (
  sportCenterId: number,
  page?: number,
  per_page?: number,
  search?: string,
  gender?: string,
  order_by?: string,
  order_direction?: string
): Promise<{ data: AllUsersResponse }> =>
  axiosInstance.get(`/sport-centers/${sportCenterId}/userMemberships`, {
    params: {
      page: page,
      per_page: per_page,
      search: search,
      gender: gender,
      order_by: order_by,
      order_direction: order_direction
    }
  });

export const createSportCenterUser = (
  data: CreateAdminUserRequestType
): Promise<{ data: AdminUserResponseType }> =>
  axiosInstance.post(`sport-centers/${data.sportCenterId}/userMemberships`, data.data);

export const singleAdminUser = (
  id: Number,
  sportCenterId: number
): Promise<{ data: UserResponse }> =>
  axiosInstance.get(`/sport-centers/${sportCenterId}/userMemberships/${id}`);

export const sendVerificationEmail = (): Promise<{ data: SuccessResponse }> =>
  axiosInstance.post('/send-verification-email');

export const updateUnverifiedEmail = (data: {
  email: string;
}): Promise<{ data: SuccessResponse }> => axiosInstance.post('/update-unverified-email', data);
